// eslint-disable-next-line import/no-unused-modules
import "@ibm/plex/css/ibm-plex.css";
import "./src/styles/index.css";

import PiwikPro from "@piwikpro/react-piwik-pro";

// eslint-disable-next-line node/no-process-env
const piwikProId = process.env.GATSBY_PIWIK_PRO_ID;

console.log("%cDesigned by BOND", "font-weight: bold");

export function onClientEntry() {
  console.log("Starting up with piwikProId", piwikProId);
  if (piwikProId) {
    PiwikPro.initialize(piwikProId, "https://mindshift.containers.piwik.pro");
  }
}
