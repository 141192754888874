module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"76906b8028e1eb39c5bbf04167f91da7"},
    },{
      plugin: require('../node_modules/@bond-london/gatsby-plugin-cms-i18next/gatsby-browser.ts'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","nb"],"defaultLanguage":"en","siteUrl":"https://www.mindshift.no","i18nextOptions":{"lowerCaseLng":true,"debug":false,"defaultNS":"translations"},"translationsQuery":"query ($language: String!) {\n      _locales: allLocale(filter: { language: { eq: $language } }) {\n        edges {\n          node {\n            ns\n            data\n            language\n          }\n        }\n      }\n    }"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TVSQ3FJ","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
